import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import ForecastStockMonthTableRow from "../../ForecastStockMonthTableRow";
import ForecastTableRow from "./ForecastTableRow";

export type ForecastCell = {
  sales: number[];
  purchase: number[];
  additionalPurchase: number[];
  adjust: number[];
  stock: number[];
  month: number[];
};

export type ForecastTableProps = {
  headerId: string;
  rowId: number;
  params: GridRenderCellParams<any, string[]>;
  forecast: ForecastCell;
  monthHeaders: string[];
  isOnlySea: boolean;
  selectedForecastCells: string[];
  setSelectedForecastCells: React.Dispatch<React.SetStateAction<string[]>>;
};

const ForecastTable: React.FC<ForecastTableProps> = React.memo(
  ({
    headerId,
    rowId,
    params,
    forecast,
    monthHeaders,
    isOnlySea,
    selectedForecastCells,
    setSelectedForecastCells,
  }) => {
    const { row: p } = params;
    return (
      <Box position="relative">
        <Table padding="none" size="small" aria-label="mini table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {monthHeaders.map((v) => (
                <TableCell align="center" key={v} style={{ fontWeight: "600" }}>
                  {v}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              { header: "Sales", values: forecast.sales, editable: true },
              { header: "Purchase", values: forecast.purchase, editable: true },
              {
                header: "Additional",
                values: forecast.additionalPurchase,
                editable: true,
                ltm_air: p.ltm_air ?? 3,
                ltm_sea: p.ltm_sea ?? 6,
                isOnlySea,
              },
              { header: "Adjust", values: forecast.adjust, editable: true },
              { header: "Stock", values: forecast.stock, editable: false },
            ].map((row, index) => (
              <ForecastTableRow
                vendor={p.vendor}
                item={p.item}
                key={index}
                headerId={headerId}
                rowId={rowId}
                editable={row.editable}
                rowHeader={row.header}
                values={row.values}
                ltm_air={row.ltm_air}
                ltm_sea={row.ltm_sea}
                isOnlySea={row.isOnlySea}
                selectedCells={selectedForecastCells}
                setSelectedCells={setSelectedForecastCells}
              />
            ))}
            <ForecastStockMonthTableRow
              rowId={rowId}
              values={forecast.month}
              minimumStockMonth={{
                air: p.msm_air,
                sea: p.msm_sea,
              }}
              leadTimeMonth={{
                air: p.ltm_air,
                sea: p.ltm_sea,
              }}
              isOnlySea={isOnlySea}
            />
          </TableBody>
        </Table>
      </Box>
    );
  }
);

export default ForecastTable;
