/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Typography } from "@mui/material";
import { GridCellParams, GridRenderCellParams } from "@mui/x-data-grid-pro";

// create type
export type SuggestSeaCellProps = GridCellParams;

const formatNumber = (value: number | undefined, defaultValue: number) => {
  return value ? Number(value).toLocaleString() : defaultValue;
};

const renderTypography = (
  label: string,
  value: number | undefined,
  defaultValue: number
) => (
  <Typography variant="subtitle2">
    {label}: {formatNumber(value, defaultValue)}
  </Typography>
);

const SuggestCell = (
  isSea: boolean,
  params: GridRenderCellParams<any, number>
) => {
  return (
    <React.Fragment>
      {renderTypography("Stock", params.row.stock, 0)}
      {renderTypography("MTD Sales", params.row.mtd_sales, 0)}
      {renderTypography("BO", params.row.back_order, 0)}
      {renderTypography("MOQ", params.row.moq, 1)}
      {renderTypography("Seasonality", params.row.seasonality_code, 6)}
      <br />
      <Typography variant="subtitle2" fontWeight="bold">
        {isSea ? "SEA" : "AIR"}
      </Typography>
      {renderTypography(
        "・DMB",
        isSea ? params.row.dmb_sea : params.row.dmb_air,
        0
      )}
      {renderTypography(
        "・LTM",
        isSea ? params.row.ltm_sea : params.row.ltm_air,
        0
      )}
      {renderTypography(
        "・MSM",
        isSea ? params.row.msm_sea : params.row.msm_air,
        0
      )}
    </React.Fragment>
  );
};

export default SuggestCell;
