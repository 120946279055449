import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CalcStockMonth from "../../../../functions/CalcStockMonth";
import HistoryTableRow from "./HistoryTableRow";
import StockMonthTableRow from "../../StockMonthTableRow";

const HistoryTable = (
  params: GridRenderCellParams<any, number[]>,
  monthHeaders: string[],
  monthRange?: number
) => {
  const blank = Array(12).fill(""); // 空行
  const { row } = params;
  const sm: string[] = CalcStockMonth(
    row.sales,
    row.stock_history,
    row.seasonality_code || 6
  );
  const rowId = params.id as number;

  return (
    <Table padding="none" size="small" aria-label="mini table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {monthHeaders
            .slice(0, 12)
            .slice(-1 * (monthRange || 12))
            .map((m) => (
              <TableCell
                padding="none"
                align="center"
                key={m}
                style={{ fontWeight: "600" }}
              >
                {m}
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[
          { header: "Sales", values: row.sales },
          { header: "", values: blank },
          { header: "", values: blank },
          { header: "Adjust", values: row.adjust_history },
          { header: "Stock", values: row.stock_history },
        ].map((row, index) => (
          <HistoryTableRow
            key={index}
            rowId={rowId}
            rowHeader={row.header}
            values={row.values}
          />
        ))}
        <StockMonthTableRow
          rowId={rowId}
          rowHeader="Month"
          minimumStockMonth={{ sea: row.msm_sea }}
          values={sm}
        />
      </TableBody>
    </Table>
  );
};

export default HistoryTable;
