import { TableCell, TableRow } from "@mui/material";
import React, { VFC, useState, useEffect, useCallback } from "react";
import { useApiPost } from "../../../../hooks/api/useApiPost";
import { useMutateDetail } from "../../../../hooks/api/mutate/useMutateDetail";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface Props {
  vendor: string;
  item: string;
  headerId: string;
  rowId: number;
  rowHeader: string;
  values: number[];
  editable: boolean;
  selectedCells: string[];
  setSelectedCells: React.Dispatch<React.SetStateAction<string[]>>;
  ltm_air?: number;
  ltm_sea?: number;
  isOnlySea?: boolean;
}

const ForecastTableRow: VFC<Props> = React.memo(
  ({
    vendor,
    item,
    headerId,
    rowId,
    rowHeader,
    values,
    editable,
    selectedCells,
    setSelectedCells,
    ltm_air,
    ltm_sea,
    isOnlySea,
  }) => {
    const [prevSelectedRow, setPrevSelectedRow] = useState(-1);
    const [cellValues, setCellValues] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);

    // セルクリックイベントハンドラ
    const handleCellClick = useCallback(
      (event: React.MouseEvent, rowId: number, cellId: string) => {
        if (prevSelectedRow !== rowId) {
          setSelectedCells([]); // 全ての選択を解除
          setPrevSelectedRow(rowId); // 前回選択された行を更新
        }

        if (event.metaKey || event.ctrlKey) {
          setSelectedCells((prev) => {
            if (prev.includes(cellId)) {
              return prev.filter((id) => id !== cellId);
            } else {
              return [...prev, cellId];
            }
          });
        } else {
          if (selectedCells.length > 0) {
            setSelectedCells([]); // 全ての選択を解除
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [prevSelectedRow]
    );

    // 値変更イベントハンドラ
    const handleValueChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const newValue = event.target.value.replace(/,/g, "");
        setCellValues((prev) => {
          const updatedValues = { ...prev };
          if (selectedCells.length === 0) {
            updatedValues[id] = newValue;
          } else {
            selectedCells.forEach((cellId) => {
              updatedValues[cellId] = newValue;
            });
          }

          return updatedValues;
        });
      },
      [selectedCells, setCellValues]
    );

    // 値のコミットイベントハンドラ
    const updateDetailField = useApiPost("detail", headerId);
    const mutateDetail = useMutateDetail(headerId);
    const handleCommit = useCallback(
      (item: string, vendor: string) => {
        const transformObject = (input: {
          [key: string]: string;
        }): { field?: string; value?: string }[] => {
          Object.keys(input).forEach((key) => {
            if (
              input[key] === "" ||
              isNaN(Number(input[key])) ||
              input[key].includes(".")
            ) {
              input[key] = "0";
            }
          });

          return Object.entries(input).map(([key, value]) => ({
            field: key,
            value: value,
          }));
        };

        setLoading(true);

        setCellValues((prevCellValues) => {
          const newCellValues = { ...prevCellValues };
          // detailの更新
          updateDetailField({
            body: {
              vendor: vendor,
              item: item,
              fieldValues: transformObject(newCellValues),
            },
          })
            .then(() => mutateDetail())
            .finally(() => setLoading(false));
          return newCellValues;
        });
      },
      [mutateDetail, updateDetailField]
    );

    // KeyDownイベントハンドラ
    const handleKeyDown = useCallback(
      (
        event: React.KeyboardEvent,
        id: string,
        itemNo: string,
        vendor: string
      ) => {
        // Enterキーが押された場合、値をコミットする
        if (event.key === "Enter") {
          handleCommit(itemNo, vendor);

          //選択を解除、セルの値をリセット
          setSelectedCells([]);
          setCellValues({});
        }

        // Escapeキーが押された場合、選択を解除する
        if (event.key === "Escape") {
          setSelectedCells([]);
        }

        // Tabキーが押された場合、次のセルにフォーカスを移す
        if (event.key === "Tab") {
          event.preventDefault();
          const targetCellIndex = values.findIndex(
            (v, i) => i === parseInt(id.split("-")[1]) - 1
          );
          const nextCellIndex =
            targetCellIndex === values.length - 1 ? 0 : targetCellIndex + 1;
          const nextCellId = `${rowHeader.toLowerCase()}-${
            nextCellIndex + 1
          }-${rowId}`;
          const nextCellElement = document.getElementById(nextCellId);
          if (nextCellElement) {
            nextCellElement.focus();
          }
        }

        // Shift + Tabキーが押された場合、前のセルにフォーカスを移す
        if (event.shiftKey && event.key === "Tab") {
          event.preventDefault();
          const targetCellIndex = values.findIndex(
            (v, i) => i === parseInt(id.split("-")[1]) - 1
          );
          const prevCellIndex =
            targetCellIndex === 0 ? values.length - 1 : targetCellIndex - 1;
          const prevCellId = `${rowHeader.toLowerCase()}-${
            prevCellIndex + 1
          }-${rowId}`;
          const prevCellElement = document.getElementById(prevCellId);
          if (prevCellElement) {
            prevCellElement.focus();
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        event.stopPropagation(); // イベントのバブリングを防止
        setSelectedCells([]); // 全ての選択を解除
      };

      const targetTableElement = document.getElementById(
        "datagrid-forecast-table"
      );

      if (targetTableElement) {
        targetTableElement.addEventListener("click", handleClickOutside);
      }

      return () => {
        if (targetTableElement) {
          targetTableElement.removeEventListener("click", handleClickOutside);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateCellId = (
      rowHeader: string,
      index: number,
      rowId: number
    ) => {
      // e.g "sales-1-1"
      return `${rowHeader.toLowerCase()}-${index + 1}-${rowId}`;
    };

    return (
      <>
        {loading && (
          <tr>
            <td>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            </td>
          </tr>
        )}
        <TableRow>
          <TableCell
            width="55"
            padding="none"
            size="small"
            component="th"
            scope="row"
          >
            {rowHeader}
          </TableCell>
          {values.map((v, i) => {
            const cellId = generateCellId(rowHeader, i, rowId);
            const cellValue =
              cellValues[cellId] !== undefined ? cellValues[cellId] : v;

            let editableCell = editable;
            if (rowHeader === "Additional") {
              // 以下は編集可能
              editableCell =
                (i === ltm_air && !isOnlySea) || // LTM Airのセル
                i === ltm_sea || // LTM Seaのセル
                (i > (ltm_sea ?? 0) && i <= (ltm_sea ?? 0) + 5); // LTM Seaから次の5つのセル(OCF用)
            }

            return (
              <TableCell
                padding="none"
                size="small"
                align="center"
                id={`id-${cellId}`}
                key={cellId}
                sx={{
                  bgcolor: selectedCells.includes(cellId)
                    ? "rgba(170,220,310,0.3)"
                    : i === ltm_air && !isOnlySea
                    ? "rgba(0,0,200,0.1)"
                    : i === ltm_sea
                    ? "rgba(0,200,0,0.12)"
                    : ltm_sea && i > ltm_sea && i <= ltm_sea + 5
                    ? "rgba(211,211,211,0.3)"
                    : "",
                  fontWeight:
                    (isOnlySea && i === ltm_sea) ||
                    (!isOnlySea && (i === ltm_air || i === ltm_sea))
                      ? "bold"
                      : "",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCellClick(event, rowId, cellId);
                }}
                onKeyDown={(event) =>
                  handleKeyDown(event, cellId, item, vendor)
                }
              >
                {editableCell ? (
                  <input
                    id={cellId}
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      height: "100%",
                      margin: 0,
                      textAlign: "center",
                      backgroundColor: "inherit",
                      boxSizing: "border-box",
                      borderBottom: "1px solid #ddd",
                      textDecoration: "underline",
                    }}
                    className="hide-spin"
                    type="number"
                    value={cellValue}
                    onChange={(event) => handleValueChange(event, cellId)}
                    max="999999999"
                    min="-999999999"
                  />
                ) : (
                  cellValue.toLocaleString()
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  }
);

export default ForecastTableRow;
