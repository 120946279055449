import { Tooltip as MUITooltip, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Grid } from "@mui/material";

const LayerCell = (params: GridRenderCellParams<any, number>) => {
  const { moq = 1, layer, pallet } = params.row;
  const tooltipNode = (
    <>
      <Typography variant="subtitle2">MOQ: {moq}</Typography>
      {layer > 1 && <Typography variant="subtitle2">Layer: {layer}</Typography>}
      {pallet > 1 && (
        <Typography variant="subtitle2">Pallet: {pallet}</Typography>
      )}
    </>
  );

  return (
    <MUITooltip title={tooltipNode} placement="right">
      <Grid>{params.value}</Grid>
    </MUITooltip>
  );
};

export default LayerCell;
