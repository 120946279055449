import { Box, CardContent, Grid, Typography } from "@mui/material";
import React, { memo, VFC } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  header: Paths.GetStockMovementDetails.Responses.$200["header"];
}

interface headerCard {
  fields: field[];
}

interface field {
  label: string;
  hide: boolean;
  values: value[];
  accordionValues?: accordionContent[];
}

interface accordionContent {
  title: string;
  content: string;
}

interface value {
  value: string | number;
  subLabel?: string;
  align?: "right" | "left";
}

const EmptyValue = "-";

const HeaderInformation: VFC<Props> = memo((props) => {
  const headerModels = headerToModel(props.header);

  return (
    <Grid container spacing={0} marginBottom={-3} marginTop={-3}>
      {headerModels.map((card, i) => (
        <Grid item xs={6} md={4} key={`header-grid-${i}`}>
          <CardContent sx={{ p: 0 }}>
            {card.fields.map((field, j) =>
              field.hide ? (
                <React.Fragment key={`card-${i}-${j}`}></React.Fragment>
              ) : (
                <FieldBox key={`card-${i}-${j}`} field={field} />
              )
            )}
          </CardContent>
        </Grid>
      ))}
    </Grid>
  );
});

const FieldBox: VFC<{ field: field }> = ({ field }) => (
  <Box
    sx={{
      borderRadius: "5px",
      p: 1,
      m: 1,
      boxShadow: 2,
      borderLeft: 1,
      borderWidth: 10,
      borderColor: "primary.main",
    }}
  >
    {field.accordionValues ? (
      headerCardAccordion(field.label, field.accordionValues)
    ) : (
      <>
        <Typography fontWeight="bold" sx={{ textDecoration: "underline" }}>
          {field.label}
        </Typography>
        {field.values.map((value, k) => (
          <ValueBox key={`value-${k}`} value={value} />
        ))}
      </>
    )}
  </Box>
);

const ValueBox: VFC<{ value: value }> = ({ value }) => (
  <Box>
    {value.subLabel && (
      <Typography sx={{ ml: 1, color: "#3D332C", fontSize: 12 }}>
        {value.subLabel}
      </Typography>
    )}
    {value.value && (
      <Typography
        sx={{
          ml: 1,
          color: "#3D332C",
          fontSize: 14,
          textAlign: value.align,
        }}
      >
        {value.value}
      </Typography>
    )}
  </Box>
);

const headerToModel = (
  header: Paths.GetStockMovementDetails.Responses.$200["header"]
): headerCard[] => {
  const createField = (
    label: string,
    value: string | number,
    hide: boolean,
    align?: "right" | "left",
    accordionValues?: accordionContent[]
  ): field => ({
    label,
    values: [{ value, align }],
    hide,
    accordionValues,
  });

  return [
    {
      fields: [
        createField("Planner Code", header.planner_code, false),
        createField("Vendor", header.vendor, false),
        createField("Only SEA", header.only_sea ? "Yes" : "No", false),
        createField("Only BO", header.only_bo ? "Yes" : "No", false),
        createField("Item", header.item || EmptyValue, false),
      ],
    },
    {
      fields: [
        createField("Alternate", header.alternates ? "Yes" : "No", false),
        createField(
          "Price Range",
          `${header.price_from || EmptyValue} to ${
            header.price_to || EmptyValue
          }`,
          false
        ),
        createField(
          "3M vs 6M Difference",
          header.sales_ratio ? `${header.sales_ratio}%` : EmptyValue,
          false
        ),
        createField(
          "Standard Deviation",
          header.standard_deviation ? "Yes" : "No",
          false
        ),
        createField(
          "AIR Weight",
          header.air_weight_summary.toLocaleString(undefined, {
            minimumFractionDigits: 3,
          }) || 0,
          !!header.only_sea,
          "right"
        ),
      ],
    },
    {
      fields: [
        createField(
          "AIR Amount",
          header.air_amount_summary.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) || 0,
          !!header.only_sea,
          "right"
        ),
        createField(
          "SEA Amount",
          header.sea_amount_summary.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }) || 0,
          false,
          "right"
        ),
        createField("Total Amount", "", false, undefined, [
          // TODO get rate from API
          { title: "MBR", content: "100000" },
          { title: "MCC", content: "100000" },
          { title: "MJ", content: "100000" },
          { title: "MMX", content: "100000" },
        ]),
        createField("Currency Late", "EUR 1.05 GBP 1.05", false, "right"),
      ],
    },
  ];
};

const headerCardAccordion = (label: string, accordions: accordionContent[]) => {
  if (!accordions) {
    return null;
  }

  return (
    <Accordion sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
      <AccordionSummary
        sx={{ padding: 0, margin: 0 }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography fontWeight="bold" sx={{ textDecoration: "underline" }}>
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {accordions.map((accordion, k) => (
          <Box key={`accordion-${k}`}>
            <Typography
              sx={{ color: "#3D332C", fontSize: 16, textAlign: "right" }}
            >
              {accordion.title} : {accordion.content}
            </Typography>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default HeaderInformation;
