type field = { [key: string]: string };

// setting for hide columns("false" is hide)
export const SeaVisibilityColumns = {
  columns: {
    columnVisibilityModel: {
      weight: false,
      weight_total: false,
      sort_weight_total: false,
      suggest_air: false,
      shortage_air: false,
      amt_air: false,
      sort_amt_air: false,
    },
  },
};

// localStorage に値があればそちらを優先し表示可否を判断する
export const DefaultVisibilityColumns = {
  columns: {
    columnVisibilityModel: {
      id: false,
      is_confirmed: false,
      vendor: true,
      flag: false,
      memo: false,
      item: true,
      item_description: true,
      status: true,
      note1: false,
      note2: false,
      note3: false,
      seasonality_code: false,
      category: false,
      location: false,
      weight: false,
      alternate: false,
      stdev_rate: false,
      history: true,
      sales_chart: false,
      av_12M: false,
      av_6M: false,
      av_3M: false,
      sales_diff: false,
      stock: true,
      stock_amount: false,
      mtd_sales: false,
      back_order: true,
      suggest_air: true,
      suggest_sea: true,
      is_apply_growth: false,
      moq: false,
      layer_suggested_qty: false,
      pallet_suggested_qty: false,
      ocf_forecast1: false,
      ocf_forecast2: false,
      ocf_forecast3: false,
      ocf_forecast4: false,
      ocf_forecast5: false,
      shortage_air: false,
      shortage_sea: false,
      forecast: true,
      price: true,
      weight_total: false,
      amt_air: true,
      amt_sea: true,
      total: true,
    },
  },
};

export const EditableColumns = [
  "suggest_air",
  "suggest_sea",
  "ocf_forecast1",
  "ocf_forecast2",
  "ocf_forecast3",
  "ocf_forecast4",
  "ocf_forecast5",
  "memo",
  "flag",
];

// エクセル出力時に並び順設定およびフィルター可能なカラム
export const CanSortFilterColumns: field[] = [
  { vendor: "Vendor" },
  { item: "Item" },
  { status: "Status" },
  { item_description: "Description" },
  { note1: "Note1" },
  { note2: "Note2" },
  { note3: "Note3" },
  { moq: "MOQ" },
  { layer: "Layer" },
  { pallet: "Pallet" },
  { seasonality_code: "Seasonality" },
  { category: "Category" },
  { location: "Location" },
  { stock: "Stock" },
  { mtd_sales: "Mtd Sales" },
  { price: "Price" },
  { back_order: "BO" },
  { ocf_forecast1: "OCF1" },
  { ocf_forecast2: "OCF2" },
  { ocf_forecast3: "OCF3" },
  { ocf_forecast4: "OCF4" },
  { ocf_forecast5: "OCF5" },
  { suggest_air: "AIR Suggested Qty" },
  { suggest_sea: "SEA Suggested Qty" },
  { is_confirmed: "Checked" },
  { alternate: "Alternate" },
  { memo: "Memo" },
  { flag: "Flag" },
  { stdev_rate: "Stdev Rate" },
  { weight: "Weight" },
  // 計算フィールド
  { total: "Total" },
  { amt_air: "Amt Air" },
  { amt_sea: "Amt Sea" },
];

export const NotSortFilterColumns: field[] = [
  { av_12M: "Av12M" },
  { av_6M: "Av6M" },
  { av_3M: "Av3M" },
  { sales_diff: "Av Diff" },
  { stock_amount: "Stock Amt" },
  { is_apply_growth: "G.Rate" },
  { layer_suggested_qty: "Layer Sug." },
  { pallet_suggested_qty: "Pallet Sug." },
  { shortage_air: "Short Air" },
  { shortage_sea: "Short Sea" },
  { weight_total: "Air Weight" },
];

export const Operators: field[] = [
  //  For Filter`s Operator
  { contains: "contains" },
  { notContains: "not contains" },
  { equal: "equal" },
  { notEquals: "not equals" },
  { startsWith: "starts with" },
  { endsWith: "ends with" },
  { isEmpty: "is empty" },
  { isAnyOf: "is any of" },
  { isNotEmpty: "is not empty" },
  // For Sort`s Operator
  { asc: "ASC" },
  { desc: "DESC" },
];

// Excel Pint Validation Error Message
export const RequiredErrMessage = "**Value is required**";
export const SelectedErrMessage = "**Cannot be set when printing**";

// Tooltip Message
export const StdevRateTooltip =
  "Multiplying factor that is calculated from standard deviation of sales histories.";

export const PlanerCodes: string[] = ["M", "A", "P", "O"];
