import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";
import { ApiRequest } from "../../../models/util/api_request_model";
import { API } from "aws-amplify";

const API_NAME = "apie413e623";

export const useMutateDetail = (headerId: number | string) => {
  const { mutate } = useSWRConfig();
  const mutateDetail = () => mutate(`/stm/stockmovement_details/${headerId}`);
  return mutateDetail;
};

export const useSWRMutationDetail = (headerId: number | string) => {
  const url = `/stm/stockmovement_details/${headerId}`;
  const fetcher = async (
    url: string,
    { arg }: { arg: ApiRequest }
  ): Promise<Paths.GetStockMovementDetails.Responses.$200> => {
    return (await API.get(API_NAME, url, arg)) as Promise<any>;
  };
  const { trigger, data, isMutating } = useSWRMutation(url, fetcher);

  return { trigger, data, isMutating };
};
